import React from "react"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Onecol from "../components/onecol"
import TwocolWithStaticImage from "../components/twocol-with-static-image"
import Cards from "../components/cards"
import ImageText from "../components/imagetext"
import ImageCTA from "../components/imageCTA"
import ButtonCTA from "../components/buttonCTA"

import Banner from "../components/banner"
import ABanner from "../images/a-banner.jpg"

import Front from "../components/images/advertising/front"

import { Flag } from "tabler-icons-react"
import { Parking } from "tabler-icons-react"
import { Bookmark } from "tabler-icons-react"
import { Space } from "tabler-icons-react"
import { Triangle } from "tabler-icons-react"
import { graphql } from "gatsby"
import Sign from "../images/a-frame-sign.jpg"
export default function Advertising({ data }) {
  const adCarImage = data.allFile.edges[0].node.childImageSharp.fluid.src
  return (
    <Layout>
      <SEO
        title="Advertising & Marketing"
        description="Reach your audience with impactful designs you can take to trade shows, conferences & events. Get your brand out front & centre & promote your business with style."
      />

      <Banner
        title="Advertising & Events"
        description="Promote your business, trade show, event or project with creative flair and an effective, impactful strategy!"
        img={ABanner}
      />

      {data.allStrapiAdvertisingEvents.edges.map(({ node }) => (
        <>
          <Onecol
            key={node.ad_intro.id}
            title={node.ad_intro.one_col_title}
            description={node.ad_intro.one_col_description}
          />

          <Cards
            key={node.ad_page_cards.id}
            linkOne="/advertising/flags"
            iconOne={<Flag size={40} strokeWidth={2} color={"#DD2E39"} />}
            titleOne={node.ad_page_cards.title_one}
            descriptionOne={node.ad_page_cards.description_one}
            linkTwo="/advertising/signs"
            iconTwo={<Parking size={40} strokeWidth={2} color={"#DD2E39"} />}
            titleTwo={node.ad_page_cards.title_two}
            descriptionTwo={node.ad_page_cards.description_two}
            linkThree="/advertising/banners"
            iconThree={<Bookmark size={40} strokeWidth={2} color={"#DD2E39"} />}
            titleThree={node.ad_page_cards.title_three}
            descriptionThree={node.ad_page_cards.description_3}
          />

          <ButtonCTA />

          <TwocolWithStaticImage
            key={node.ad_two_col_left.id}
            grey={true}
            textLeft={false}
            intro={node.ad_two_col_left.two_col_intro}
            iconOne={
              <Space
                size={40}
                strokeWidth={2}
                color={"#DD2E39"}
                className="rotate-icon"
              />
            }
            titleOne={node.ad_two_col_left.title_one}
            linkOne="/advertising/table-drapes"
            descriptionOne={node.ad_two_col_left.description_one}
            iconTwo={<Triangle size={40} strokeWidth={2} color={"#DD2E39"} />}
            titleTwo={node.ad_two_col_left.title_two}
            linkTwo="/advertising/tents"
            descriptionTwo={node.ad_two_col_left.description_two}
          >
            <Front />
          </TwocolWithStaticImage>

          <ImageText
            key={node.ad_two_col_plain.id}
            background={Sign}
            textleft={true}
            title={node.ad_two_col_plain.two_col_title}
            description={node.ad_two_col_plain.two_col_description}
          />

          <ImageCTA
            title="We offer a complete line of vehicle and marine wraps."
            image={adCarImage}
            link="/contact-us"
            linkText="inquire today"
          />
        </>
      ))}
    </Layout>
  )
}
// export default AdvertisingMarketing

export const query = graphql`
  query Advertising {
    allFile(filter: { relativePath: { eq: "vehicle-wrap.jpg" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 2000) {
              originalName
              src
            }
          }
        }
      }
    }
    allStrapiAdvertisingEvents {
      edges {
        node {
          advertising_hero {
            id
            hero_title
            hero_description
          }
          ad_intro {
            id
            one_col_title
            one_col_description
          }
          ad_page_cards {
            id
            title_one
            description_one
            title_two
            description_two
            title_three
            description_3
          }
          ad_two_col_left {
            id
            two_col_intro
            title_one
            description_one
            title_two
            description_two
          }
          ad_two_col_plain {
            id
            two_col_title
            two_col_description
          }
        }
      }
    }
  }
`
